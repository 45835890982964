@import "./../required";

$accordion-padding-y:           1rem;
$accordion-padding-x:           1.25rem;
$accordion-bg:                  $body-bg;
$accordion-border-color:        $border-color;
$accordion-border-radius:       $border-radius;

$accordion-button-active-bg:    $gray-200;

$accordion-icon-width:          1.25rem;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

.accordion-item {
    overflow: hidden;
    border: 1px solid $accordion-border-color;

    &:not(:first-of-type) {
        border-top: 0;
    }

    &:first-of-type {
        border-top-left-radius: $accordion-border-radius;
        border-top-right-radius: $accordion-border-radius;
    }

    &:last-of-type {
        border-bottom-right-radius: $accordion-border-radius;
        border-bottom-left-radius: $accordion-border-radius;
    }
}

.accordion-btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: $accordion-padding-y $accordion-padding-x;
    text-align: left;
    background-color: $accordion-bg;
    border: 0;

    &::after {
        display: inline-block;
        width: $accordion-icon-width;
        height: $accordion-icon-width;
        margin-left: auto;
        content: "";
        background-image: $accordion-button-icon;
        background-repeat: no-repeat;
        background-size: $accordion-icon-width;
    }

    &:not(.collapsed) {
        background-color: $accordion-button-active-bg;
        box-shadow: 0 1px 1px $accordion-border-color;

        &::after {
            transform: rotate(-180deg);
        }
    }
}

.accordion-body {
    padding: $accordion-padding-y $accordion-padding-x;
}
