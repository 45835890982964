@use "sass:map";
@use "sass:color";

@import "./../required";

.the-footer {
    order: 4;
    font-size: $font-size-sm;
    background-color: $white;

    content-visibility: auto;
    contain-intrinsic-size: auto 1px auto 500px;

    > * {
        padding-top: map.get($spacers, 4);
        padding-bottom: map.get($spacers, 4);
    }

    a {
        color: $body-color;

        &:hover {
            color: color.adjust($body-color, $lightness: -15%);
        }
    }

    p:only-child {
        margin-bottom: 0;
    }

    .btn-apps {
        padding-right: 0;
        padding-left: 0;
    }

    .accordion-body {
        margin-bottom: 0;
        list-style: none;

        li {
            margin-bottom: map.get($spacers, 2);
        }
    }

    .accordion-item {
        border-right: 0;
        border-left: 0;
        border-radius: 0;

        img {
            max-width: 100%;
        }

        &.quick-links {

            .accordion-body {
                columns: 2;
            }
        }
    }

    .accordion-item ~ *:not(.accordion-item) .accordion-item {
        border-top: 0;
    }

    .accordion-btn {
        font-weight: $font-weight-bold;
        background-color: $white;
    }

    @include media-breakpoint-up(md) {
        .accordion-item {
            border: 0;
        }

        .accordion-btn {
            padding-top: 0;
            padding-bottom: 0;
            font-size: $h6-font-size;
            color: $gray-900;
            box-shadow: none;

            &::after {
                display: none;
            }
        }

        .copyright {
            border-top: $border-width solid $border-color;
        }
    }
}
